<template>
  <div>
    <div class="buy_box">
      <div :class="obj.status == 0 ? 'active' : ''" @click="obj.status = 0">
        {{ $t("main.text62") }}
      </div>
      <div :class="obj.status == 5 ? 'active' : ''" @click="obj.status = 5">
        {{ $t("main.text63") }}
      </div>
      <div :class="obj.status == 6 ? 'active' : ''" @click="obj.status = 6">
        {{ $t("main.text64") }}
      </div>
    </div>
    <div class="list_box" v-if="dataList.length > 0">
      <div
        class="list_item"
        v-for="(item, index) in dataList"
        :key="index"
        @click="routerPush(item)"
      >
        <div class="i_top">{{ item.c_time | time }}</div>
        <div class="i_box">
          <div class="i_box_1">
            {{ item.type == 1 ? $t("main.text47") : $t("main.text52") }}Hicoin
          </div>
          <div class="i_box_2">
            <div class="i_box_3">
              {{ num_format(item.currency_amount) }} {{ item.currency }}
            </div>
            <div class="i_box_3">
              <!-- <span v-if="item.status == 1">订单确认中</span>
              <span v-else-if="item.status == 2" class="color_red">
                待上传凭证
              </span>
              <span v-else-if="item.status == 3">待处理</span>
              <span v-else-if="item.status == 4" class="color_red">
                用户申诉
              </span>
              <span v-else-if="item.status == 5" class="color_red">
                代理申诉
              </span>
              <span v-else-if="item.status == 6">用户取消</span>
              <span v-else-if="item.status == 7">代理取消</span>
              <span v-else-if="item.status == 8">后台取消</span>
              <span v-else-if="item.status == 9">用户完成</span>
              <span v-else-if="item.status == 10">代理完成</span>
              <span v-else-if="item.status == 11">后台完成</span> -->

              <span v-if="item.status == 6">{{ $t("main.text64") }}</span>
              <span v-else-if="item.status == 5" class="color_red">
                {{ $t("main.text63") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="list_item">
        <div class="i_top">2023-12-12</div>
        <div class="i_box">
          <div class="i_box_1 color_green">出售Hicoin</div>
          <div class="i_box_2">
            <div class="i_box_3">18.25 CNY</div>
            <div class="i_box_3 color_red">待上传凭证</div>
          </div>
        </div>
        <div class="i_box">
          <div class="i_box_1 color_green">出售Hicoin</div>
          <div class="i_box_2">
            <div class="i_box_3">18.25 CNY</div>
            <div class="i_box_3 color_red">待上传凭证</div>
          </div>
        </div>
        <div class="i_box">
          <div class="i_box_1 color_green">出售Hicoin</div>
          <div class="i_box_2">
            <div class="i_box_3">18.25 CNY</div>
            <div class="i_box_3 color_red">待上传凭证</div>
          </div>
        </div>
      </div> -->
      <div class="footer" v-if="count > 0">
        <van-pagination
          v-model="currentPage"
          :total-items="count"
          :show-page-size="3"
          force-ellipses
          @change="selectPage"
        />
      </div>
    </div>
    <div class="list_box" v-else>
      <div class="noData">{{ $t("main.text59") }}</div>
    </div>
  </div>
</template>

<script>
import { orders_list } from "../../../api/api";
import { time_format } from "../../../assets/tool/index";

export default {
  filters: {
    time(v) {
      return time_format(v);
    },
  },
  data() {
    return {
      index_active: 1,
      obj: {
        page: 0,
        limit: 10,
        orderStatus: 2,
        status: 0,
      },
      dataList: [],
      count: 0,
      currentPage: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    routerPush(row) {
      // if (row.currency == "USDT") {
      //   this.$router.push({
      //     path: "/buy_jmhb_info",
      //     query: { order_id: row.order_id },
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/buy_fiat_info",
      //     query: { order_id: row.order_id },
      //   });
      // }

      this.$router.push({
        path: "/buy_fiat_info",
        query: { order_id: row.order_id },
      });
    },
    getList() {
      orders_list(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.count = res.data.data.amountAll;
        }
      });
    },
    selectPage() {
      this.obj.page = this.currentPage - 1;
      this.getList();
    },
  },
  watch: {
    "obj.status": function () {
      this.getList();
    },
  },
};
</script>

<style scoped>
.buy_box {
  display: flex;
  background: #fff;
}
.buy_box > div {
  /* width: 50%; */
  /* text-align: center; */
  box-sizing: border-box;
  padding: 0.65rem 0;
  margin-left: 1.25rem;
  font-size: 0.875rem;
  color: #adadad;
}
.buy_box .active {
  color: #2982d8;
  border-bottom: 1.5px solid #2982d8;
}
.list_box {
  box-sizing: border-box;
  padding: 0 1.13rem;
}
.list_item {
  margin-top: 1.25rem;
}
.i_top {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  background: #2982d8;
  box-sizing: border-box;
  padding: 0.63rem 1rem;
}
.i_box {
  box-sizing: border-box;
  padding: 1.25rem 1rem;
  border-radius: 0 0 0.375rem 0.375rem;
  background: #fff;
  border-bottom: 0.0625rem solid #f5f6f7;
}
.list_item .i_box:last-child {
  border-bottom: none;
}
.i_box_1 {
  color: #666;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}
.i_box_2 {
  display: flex;
  justify-content: space-between;
}
.i_box_3 {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}
.color_red {
  color: #f62727;
}
.color_green {
  color: #11bf71;
}
.footer {
  padding: 1rem 0;
}
.noData {
  margin-top: 7rem;
}
</style>
